import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

const Header = () => {
  const links = [
    {
      to: '/',
      name: 'Home',
    },
    {
      to: '/portfolio',
      name: 'Portfolio',
    },
    {
      to: '/contact',
      name: 'Contact',
    },
  ];
  const getDeviceWidth = () => document.documentElement.clientWidth;
  return (
    <Box>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <Box textAlign={getDeviceWidth() >= 800 ? 'left' : 'center'}>
            <Typography variant="h4" color="secondary">
              Pixel Key Photography
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box pt={4} pb={2} textAlign="center">
            {links.map(({ to, name }, index) => (
              <Box key={index} component="span" pl={3}>
                <Link to={to}>{name}</Link>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Header;
