import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Contact from '../Contact/Contact';
import Home from '../Home';
import Portfolio from '../Portfolio';

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/portfolio',
    component: Portfolio,
  },
  {
    path: '/contact',
    component: Contact,
  },
];

const RouterSwitch = () => (
  <Switch>
    {routes.map((props, index) => (
      <Route key={index} {...props} />
    ))}
  </Switch>
);

export default RouterSwitch;
