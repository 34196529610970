import React from 'react';
import { Box } from '@material-ui/core';
import PageAnimation from '../../components/PageAnimation';

const Contact = () => {
  return (
    <PageAnimation>
      <Box pt={4}>Contact</Box>
    </PageAnimation>
  );
};

export default Contact;
