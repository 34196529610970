import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  Grow,
} from '@material-ui/core';

export default function MediaCard({ title, description, coverImagePath }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const useStyles = makeStyles({
    root: {
      maxWidth: 400,
      height: '100%',
    },
    media: {
      objectFit: 'cover',
      width: '100%',
      height: 200,
      opacity: imageLoaded ? 1 : 0,
    },
  });

  const classes = useStyles();

  return (
    <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 }}>
      <Card className={classes.root}>
        <CardActionArea>
          <Box width="100%">
            <img
              className={classes.media}
              src="images/man.jpg"
              alt="man"
              onLoad={() => setImageLoaded(true)}
            />
          </Box>

          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grow>
  );
}
