import React from 'react';
import { Container, ThemeProvider } from '@material-ui/core';
import Header from '../../components/Header';

import { BrowserRouter as Router } from 'react-router-dom';

import RouterSwitch from './RouterSwitch';
import theme from '../../utils';

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg">
          <Header />
          <RouterSwitch />
          {/* <Footer /> */}
        </Container>
      </ThemeProvider>
    </Router>
  );
};

export default App;
