import React from 'react';
import { Slide } from '@material-ui/core';

const PageAnimation = ({ children }) => {
  return (
    <Slide direction="left" in={true} {...{ timeout: 800 }}>
      {children}
    </Slide>
  );
};

export default PageAnimation;
