import React, { useState } from 'react';
import { Box, Grid, Grow, makeStyles, Typography } from '@material-ui/core';
import PageAnimation from '../../components/PageAnimation';
import SocialButtons from '../../components/SocialButtons';

const Home = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const useStyles = makeStyles({
    image: {
      borderRadius: '50%',
      objectFit: 'cover',
      opacity: imageLoaded ? 1 : 0,
    },
    gridItemContainer: {
      textAlign: 'center',
    },
  });
  const classes = useStyles();

  return (
    <PageAnimation>
      <Box pt={4}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} md={4}>
            <Grow
              in={true}
              style={{ transformOrigin: '0 0 0' }}
              {...{ timeout: 1000 }}>
              <Box pt={8} className={classes.gridItemContainer}>
                <img
                  className={classes.image}
                  src="images/man.jpg"
                  alt="man"
                  width="250"
                  height="250"
                  onLoad={() => setImageLoaded(true)}
                />
              </Box>
            </Grow>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box pt={4} className={classes.gridItemContainer}>
              <Typography variant="body1" color="secondary">
                A Photographer by hobby. <br />I love to keep things simple and
                minimal.
              </Typography>
            </Box>
            <Box pt={2} textAlign="center">
              <SocialButtons />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageAnimation>
  );
};

export default Home;
