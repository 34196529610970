import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

const socialIcons = [
  {
    icon: <InstagramIcon />,
    url: 'https://www.instagram.com/pixelkeyphotography/',
  },
  {
    icon: <FacebookIcon />,
    url: 'https://www.facebook.com/thepixelkey',
  },
];

const SocialButtons = () => {
  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box>
      {socialIcons.map(({ icon, url }, index) => (
        <Box component="span" key={index}>
          <IconButton
            {...{ 'aria-label': icon }}
            color="secondary"
            onClick={() => handleClick(url)}>
            {icon}
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};

export default SocialButtons;
