import React from 'react';
import { Box, Grid } from '@material-ui/core';
import PageAnimation from '../../components/PageAnimation';
import MediaCard from '../../components/MediaCard';

const Portfolio = () => {
  const albums = [
    {
      title: 'Couples',
      description: 'Photography is an amazing artwork',
      coverImagePath: 'images/man.jpg',
    },
    {
      title: 'Portraits',
      description: 'My hobby and a perfect element to work on',
      coverImagePath: 'images/man.jpg',
    },
    {
      title: 'Space',
      description: 'Something that I love and passionate about',
      coverImagePath: 'images/man.jpg',
    },
    {
      title: 'Babies',
      description: 'Something that I love and passionate about',
      coverImagePath: 'images/man.jpg',
    },
    {
      title: 'Wedding',
      description: 'Something that I love and passionate about',
      coverImagePath: 'images/man.jpg',
    },
    {
      title: 'Babies',
      description: 'Something that I love and passionate about',
      coverImagePath: 'images/man.jpg',
    },
    {
      title: 'Wedding',
      description: 'Something that I love and passionate about',
      coverImagePath: 'images/man.jpg',
    },
    {
      title: 'Wedding',
      description: 'Something that I love and passionate about',
      coverImagePath: 'images/man.jpg',
    },
  ];
  return (
    <PageAnimation>
      <Box pt={4}>
        <Grid container spacing={1}>
          {albums.map((props, index) => (
            <Grid item key={index} md={3} xs={12}>
              <MediaCard {...props} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </PageAnimation>
  );
};

export default Portfolio;
